import React from "react";

function Explore() {
  return (
    <h2 className="uk-heading-line uk-text-center">
      <span>Explore the right solutions for your feedback needs.</span>
    </h2>
  );
}

export default Explore;
