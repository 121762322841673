import React from "react";
import PropTypes from "prop-types";
import External from "../components/External";
import Grid from "../components/Grid";
import Margin from "../components/Margin";
import Text from "../components/Text";

function Product({ title, description, gif, link, slogan, color, image }) {
  return (
    <Grid>
      <div className="uk-width-2-5@m">
        <Margin>
          <Grid small x="left">
            <Text elementType="h1" weight="bold" color={color}>
              {title}
            </Text>
            <Text>-</Text>
            <Text elementType="h3" transform="uppercase">
              {slogan}
            </Text>
          </Grid>
        </Margin>
        <Margin>
          <Text elementType="h4">{description}</Text>
        </Margin>
        <Margin>
          <External href={link} className="uk-button uk-button-danger">
            get started
          </External>
        </Margin>
      </div>
      <div className={`uk-width-3-5@m ${image ? "uk-flex-first@m" : ""}`}>
        <img src={gif} alt={title} />
      </div>
    </Grid>
  );
}

Product.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  gif: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  slogan: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  image: PropTypes.bool,
};

Product.defaultProps = {
  image: false,
};

export default Product;
