import React from "react";
import PropTypes from "prop-types";

function Width({ children, size }) {
  const className = `uk-width-${size}`;
  return <div className={className}>{children}</div>;
}

Width.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.string.isRequired,
};

export default Width;
