import React from "react";
import PropTypes from "prop-types";
import { pad } from "../lib";

function Section({ children, type, small, navbar }) {
  let className = "uk-section";
  className += pad(`uk-section-${type}`);
  if (small) className += pad("uk-section-small");
  if (["primary", "secondary"].includes(type)) className += pad("uk-light");
  if (navbar) className += pad("uk-padding-remove");
  return (
    <div className={className}>
      <div className="uk-container">{children}</div>
    </div>
  );
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  small: PropTypes.bool,
  navbar: PropTypes.bool,
};

Section.defaultProps = {
  small: false,
  navbar: false,
};

export default Section;
