import React from "react";
import PropTypes from "prop-types";
import { pad } from "../lib";

function Grid({ children, small, x, y, width }) {
  let className = "uk-grid";
  if (small) className += pad("uk-grid-small");
  className += pad(`uk-flex-${x}`);
  className += pad(`uk-flex-${y}`);
  className += pad(`uk-child-width-${width}`);
  return (
    <div className={className} data-uk-grid>
      {children}
    </div>
  );
}

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  small: PropTypes.bool,
  x: PropTypes.string,
  y: PropTypes.string,
  width: PropTypes.string,
};

Grid.defaultProps = {
  small: false,
  x: "center",
  y: "middle",
  width: "auto",
};

export default Grid;
