import React from "react";
import Section from "../components/Section";
import Footer from "../components/Footer";
import Lead from "./Lead";
import Explore from "./Explore";
import data from "../data";
import Product from "./Product";

function Scroll({ target }) {
  return (
    <div className=" uk-text-center">
      <a
        data-uk-scroll
        data-uk-icon="icon: arrow-down; ratio: 5"
        className="uk-icon-button"
        href={`#${target}`}
      ></a>
    </div>
  );
}

function Landing() {
  return (
    <div>
      <div
        data-uk-height-viewport="offset-top: true"
        className="uk-flex uk-flex-middle uk-flex-center"
      >
        <div>
          <Section type="default">
            <Lead />
          </Section>
          <Section type="default" small>
            <Explore />
          </Section>
          <Section type="default" small>
            <Scroll target="0" />
          </Section>
        </div>
      </div>

      {data.map((item, index) => (
        <div
          key={item.title}
          id={index}
          data-uk-height-viewport="offset-top: true"
          className="uk-background-muted uk-flex uk-flex-middle uk-flex-center"
        >
          <div>
            <Section type="muted">
              <Product {...item} image={index % 2 === 1} />
            </Section>
            {index < data.length - 1 && (
              <Section type="muted" small>
                <Scroll target={index + 1} />
              </Section>
            )}
          </div>
        </div>
      ))}

      <Section type="secondary" small>
        <Footer />
      </Section>
    </div>
  );
}

export default Landing;
