import React from "react";
import PropTypes from "prop-types";
import { pad } from "../lib";

function Text({
  children,
  elementType,
  size,
  weight,
  transform,
  color,
  align,
  wrap,
}) {
  let className = "";
  if (size) className += pad(`uk-text-${size}`);
  if (weight) className += pad(`uk-text-${weight}`);
  if (transform) className += pad(`uk-text-${transform}`);
  if (color) className += pad(`uk-text-${color}`);
  if (align) className += pad(`uk-text-${align}`);
  if (wrap) className += pad(`uk-text-${wrap}`);
  const Component = elementType;
  return <Component className={className}>{children}</Component>;
}

Text.propTypes = {
  children: PropTypes.node.isRequired,
  elementType: PropTypes.elementType,
  size: PropTypes.string,
  weight: PropTypes.string,
  transform: PropTypes.string,
  color: PropTypes.string,
  align: PropTypes.string,
  wrap: PropTypes.string,
};

Text.defaultProps = {
  elementType: "div",
  size: "",
  weight: "",
  transform: "",
  color: "",
  align: "",
  wrap: "",
};

export default Text;
