import cliiick from "./cliiick.png";
import cliiickGif from "./cliiick.gif";
import contentfeedback from "./contentfeedback.png";
import contentfeedbackGif from "./contentfeedback.gif";
import annootate from "./annootate.png";
import annootateGif from "./annootate.gif";

const data = [
  {
    logo: cliiick,
    title: "CliiiCK",
    description:
      "Multimedia polling to help you gather votes and feedback from your audience or from our global consumer network.",
    link: "https://www.cliiick.com",
    slogan: "for your polls",
    gif: cliiickGif,
    color: "danger",
  },
  {
    logo: annootate,
    title: "Annootate",
    description:
      "Streamlines giving and receiving feedback on documents, websites and images using annotation tools and multimedia commenting.",
    link: "https://www.annootate.com",
    slogan: "for your documents",
    gif: annootateGif,
    color: "primary",
  },
  {
    logo: contentfeedback,
    title: "ContentFeedback",
    description:
      "Provides feedback for content creators to identify their audience, increase engagement and grow monetization.",
    link: "https://www.contentfeedback.co",
    slogan: "for your content",
    gif: contentfeedbackGif,
    color: "danger",
  },
];

export default data;
