import React from "react";
import Grid from "../components/Grid";
import Margin from "../components/Margin";
import Width from "../components/Width";
import Text from "../components/Text";
import logo from "./logo.gif";
import image from "./image.svg";

function Lead() {
  return (
    <Grid>
      <Width size="3-5@m">
        <Margin>
          <img src={logo} alt="logo" className="uk-height-small" />
        </Margin>
        <Margin>
          <Text elementType="h1" color="danger" weight="bold">
            Crowdsource feedback the easy way with the right tools.
          </Text>
        </Margin>
        <Margin>
          <Text elementType="h4">
            <ul className="uk-list uk-list-disc">
              <li>Meaningful, actionable & insightful feedback.</li>
              <li>For professional, academic and personal projects.</li>
              <li>Make better data-informed decisions.</li>
            </ul>
          </Text>
        </Margin>
      </Width>
      <Width size="2-5@m">
        <img src={image} alt="lead" />
      </Width>
    </Grid>
  );
}

export default Lead;
