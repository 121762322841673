import React from "react";
import Left from "./Left";
import Center from "./Center";
import Right from "./Right";
import Grid from "../Grid";
import Text from "../Text";

function Footer() {
  return (
    <Text size="small">
      <Grid width="1-3@m" y="top">
        <div>
          <Left />
        </div>
        <div>
          <Center />
        </div>
        <div>
          <Right />
        </div>
      </Grid>
    </Text>
  );
}

export default Footer;
