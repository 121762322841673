import React from "react";
import MailChimp from "react-mailchimp-subscribe";
import Subscribe from "./Subscribe";
import Margin from "../Margin";
import { SUBSCRIBE_URL } from "../../constants";
import Text from "../Text";

function Center() {
  return (
    <div>
      <Margin small>
        <Text elementType="h5">Subscribe to updates</Text>
      </Margin>
      <Margin small>
        <MailChimp
          url={SUBSCRIBE_URL}
          render={({ subscribe, status, message }) => (
            <Subscribe
              onValidated={subscribe}
              status={status}
              message={message}
            />
          )}
        />
      </Margin>
    </div>
  );
}

export default Center;
