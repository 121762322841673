import React, { useState } from "react";
import PropTypes from "prop-types";
import { validateEmail } from "../../lib";
import Margin from "../Margin";
import Button from "../Button";

function Subscribe({ onValidated, status, message }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    if (name && email && validateEmail(email))
      onValidated({ EMAIL: email, NAME: name });
  }

  return (
    <form className="uk-form-stacked" onSubmit={handleSubmit}>
      <Margin small>
        <div className="uk-form-controls uk-inline">
          <button type="button" className="uk-form-icon" data-uk-icon="user" />
          <input
            type="text"
            className="uk-input uk-form-small"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Your name"
            required
          />
        </div>
      </Margin>

      <Margin small>
        <div className="uk-form-controls uk-inline">
          <button type="button" className="uk-form-icon" data-uk-icon="mail" />
          <input
            type="email"
            className="uk-input uk-form-small"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your email"
            required
          />
        </div>
      </Margin>

      <Margin small>
        <Button type="default" submit small>
          subscribe
        </Button>
      </Margin>

      <Margin small>
        {status === "sending" && <span>subscribing...</span>}
        {status === "error" && (
          <span dangerouslySetInnerHTML={{ __html: message }} />
        )}
        {status === "success" && (
          <span dangerouslySetInnerHTML={{ __html: message }} />
        )}
      </Margin>
    </form>
  );
}

Subscribe.propTypes = {
  onValidated: PropTypes.func.isRequired,
  status: PropTypes.string,
  message: PropTypes.string,
};

Subscribe.defaultProps = {
  status: "",
  message: "",
};

export default Subscribe;
