import React from "react";
import {
  FACEBOOK_URL,
  TWITTER_URL,
  LINKEDIN_URL,
  INSTAGRAM_URL,
  YOUTUBE_URL,
} from "../../constants";
import External from "../External";
import Margin from "../Margin";
import Grid from "../Grid";

function Left() {
  const year = new Date().getFullYear();

  return (
    <div>
      <Margin small>
        <Grid small x="left">
          <span data-uk-icon="location" />
          <External href="https://goo.gl/maps/PDLZK1RCXyKJWQtc6">
            5 Parvis Alan Turing
          </External>
        </Grid>
      </Margin>

      <Margin small>
        <Grid small x="left">
          <span data-uk-icon="mail" />
          <External href="mailto:hello@ezzyfeedback.com">
            hello@ezzyfeedback.com
          </External>
        </Grid>
      </Margin>

      <Margin small>
        <Grid small x="left">
          <External href={FACEBOOK_URL}>
            <span data-uk-icon="facebook" />
          </External>
          <External href={TWITTER_URL}>
            <span data-uk-icon="twitter" />
          </External>
          <External href={LINKEDIN_URL}>
            <span data-uk-icon="linkedin" />
          </External>
          <External href={INSTAGRAM_URL}>
            <span data-uk-icon="instagram" />
          </External>
          <External href={YOUTUBE_URL}>
            <span data-uk-icon="youtube" />
          </External>
        </Grid>
      </Margin>

      <Margin small>
        <div>Copyright © {year} EzzyFeedback Inc.</div>
        <div>All rights reserved.</div>
      </Margin>
    </div>
  );
}

export default Left;
