import React from "react";
import External from "../External";
import data from "../../data";
import Grid from "../Grid";

function Right() {
  return (
    <ul className="uk-list">
      {data.map((item) => (
        <li key={item.title}>
          <External href={item.link}>
            <Grid small x="left">
              <div>
                <img
                  src={item.logo}
                  alt="logo"
                  className="uk-border-circle"
                  width="20"
                  height="20"
                />
              </div>
              <div>{item.title}</div>
            </Grid>
          </External>
        </li>
      ))}
    </ul>
  );
}

export default Right;
