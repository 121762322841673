import React from "react";
import PropTypes from "prop-types";
import { pad } from "../lib";

function Button({ children, type, onClick, submit, small, full, disabled }) {
  let className = "uk-button";
  className += pad(`uk-button-${type}`);
  if (small) className += pad("uk-button-small");
  if (full) className += pad("uk-width-1-1");
  return (
    <button
      type={submit ? "submit" : "button"}
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  submit: PropTypes.bool,
  small: PropTypes.bool,
  full: PropTypes.bool,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  onClick: undefined,
  submit: false,
  small: false,
  full: false,
  disabled: false,
};

export default Button;
