import React from "react";
import PropTypes from "prop-types";

function Margin({ children, small }) {
  const className = small ? "uk-margin-small" : "uk-margin";
  return <div className={className}>{children}</div>;
}

Margin.propTypes = {
  children: PropTypes.node.isRequired,
  small: PropTypes.bool,
};

Margin.defaultProps = {
  small: false,
};

export default Margin;
